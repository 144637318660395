import gen from 'random-seed';
import md5 from 'md5';

export type ValidatePasswordOutput = {
  isValid: boolean;
  policy: {
    minLength: boolean;
    uppercase: boolean;
    specialChar: boolean;
    number: boolean;
  };
};

export function countryCodeToEmoji(countryCode: string): string {
  if (!countryCode) {
    return '';
  }
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + (char as any).charCodeAt());
  return String.fromCodePoint(...codePoints);
}

export function validatePassword(pwd: string): ValidatePasswordOutput {
  const policy = {
    minLength: pwd.length >= 8,
    uppercase: /[A-Z]/.test(pwd),
    specialChar: /[@$!%*?&]/.test(pwd),
    number: /\d/.test(pwd),
  };
  const isValid = Object.values(policy).every(Boolean);
  return { isValid, policy };
}

export function toFixed(float: number, decimals: number = 2): number {
  // This converts the number into a float, rounds to 2 decimals and then converts it back to a number (float or int).
  // Using Math.round() would not work because it would round to the nearest integer, which is not what we want if the number is a float.
  return +parseFloat(float.toString()).toFixed(decimals);
}

/**
 *
 * If the total includes a fraction of a day, it is rounded up.
 * If the fraction of a day is below a half day it is rounded up to the next half day,
 * if it is above a half-day it is rounded up to the next whole day. If the fraction is already exactly a half day, it does not need to be rounded up
 *
 * @param float number
 * @returns number
 */
export function round(float: number): number {
  const decimals = float % 1;
  let addition: number = 0;

  switch (true) {
    case decimals > 0.5:
      addition = 1;
      break;
    case decimals === 0.5:
    case decimals > 0.00000000000001:
      addition = 0.5;
      break;
    case decimals === 0:
      addition = 0;
      break;
  }

  return parseInt(float.toString(), 10) + addition;
}

export const fetcher = async (url) => {
  const res = await fetch(url);

  if (!res.ok) {
    const error = new Error(
      'An error occurred while fetching the data.',
    ) as Error & { info: string; status: number };
    error.info = await res.text();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export function seededRandom(seed: string, min: number, max: number): number {
  const rand = gen.create(seed);
  return rand.intBetween(min, max);
}

export const isServer = typeof window === 'undefined';

export const localhostURL = 'localhost:3000';
export const productionHostURL = 'app.givver.io';

export const currentURL = isServer
  ? process.env.VERCEL_ENV === 'production'
    ? productionHostURL
    : (process.env.VERCEL_URL ?? localhostURL)
  : `${window.location.hostname}:${window.location.port}`;

export const isLocal = currentURL === localhostURL;

export const currentHTTPScheme = `http${isLocal ? '' : 's'}://`;

export function sortObjectKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys);
  }
  if (obj !== null && typeof obj === 'object') {
    const sortedKeys = Object.keys(obj).sort();
    const result: { [key: string]: any } = {};
    for (const key of sortedKeys) {
      result[key] = sortObjectKeys(obj[key]);
    }
    return result;
  }
  return obj;
}

export function replaceValuesInObject<T = object, E = object>(
  obj: T,
  target: string,
  replacement: any,
): E {
  if (!obj) {
    return {} as E;
  }

  const result = { ...obj };

  for (const key in result) {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      const value = result[key];

      if (value === null || value === undefined) {
        continue;
      }

      if (typeof value === 'object') {
        result[key] = replaceValuesInObject(value, target, replacement);
      } else if ((value as unknown as string) === target) {
        result[key] = replacement;
      }
    }
  }

  return result as unknown as E;
}

export function getObjectMD5(obj: object): string {
  return md5(JSON.stringify(sortObjectKeys(obj)));
}
