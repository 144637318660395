import qs from 'querystring';
import useSWR from 'swr';
import { AllEmployeeInCompany } from 'lib/types/company';
import { fetcher } from 'lib/utils';

type UseCompanyEmployeesProps = {
  limit?: number;
  offset?: number;
  q?: string;
  status?: number[];
};

export function useCompanyEmployees(props: UseCompanyEmployeesProps) {
  const queryString = qs.stringify(props);

  const { data, error, mutate } = useSWR<AllEmployeeInCompany>(
    `/api/v1/company/get-all-employees-in-my-company?${queryString}`,
    fetcher,
  );

  console.log(data);

  return {
    data,
    loading: !error && !data,
    error,
    refetch: () => mutate(),
  };
}
